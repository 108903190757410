import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import dynamic from "next/dynamic";
import React, { useRef } from "react";
// import HeroDotGlobe from "../../../ui/HeroDotGlobe";
import LightSvg from "../../common/ui/LightSvg";
import HeroTop from "./HeroTop";
import USPHome from "./USPHome";

const DynamicStarCanvas = dynamic(() => import("../../../ui/StarCanvas"), {
    ssr: false
});

const DynamicWaveCanvas = dynamic(() => import("../../../ui/WaveCanvas"), {
    ssr: false
});

const DynamicCandleCanvas = dynamic(() => import("../../../ui/CandleCanvas"), {
    ssr: false
});

const DynamicCylinderBox = dynamic(() => import("../../common/ui/CylinderBox"));

const Hero = () => {
    const hero = useRef(null);
    const uspRight = useRef(null);
    useGSAP(
        () => {
            const tab = gsap?.matchMedia();
            tab.add("(min-width: 1280px)", () => {
                gsap.to(".glob-box", {
                    x: 0,
                    opacity: 0.7,
                    duration: 0.5,
                    delay: 1.1
                });
                gsap.to(".glob-box", {
                    y: uspRight?.current?.getBoundingClientRect()?.top - 480,
                    x: -uspRight?.current?.getBoundingClientRect()?.left + 300,
                    scrollTrigger: {
                        trigger: hero.current,
                        start: "20% 20%",
                        end: "35% 20%",
                        toggleActions: "play none none reverse",
                        scrub: 3,
                        onEnter: () => {
                            gsap.to(".globe-bottom-grad", {
                                opacity: 1,
                                duration: 0.5
                            });
                            gsap.to(".globe-right-grad", {
                                opacity: 0,
                                duration: 0.5
                            });
                        },
                        onLeaveBack: () => {
                            gsap.to(".globe-bottom-grad", {
                                opacity: 0,
                                duration: 0.5
                            });
                            gsap.to(".globe-right-grad", {
                                opacity: 1,
                                duration: 0.5
                            });
                        }
                    }
                });
                const candleWaveBox =
                    hero.current.querySelector(".candle-wave-box");
                gsap.to(candleWaveBox, {
                    y: 100,
                    opacity: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: hero.current,
                        start: "20% 20%",
                        end: "20% 20%",
                        toggleActions: "play none none reverse"
                    }
                });
                gsap?.to(uspRight?.current, {
                    y:
                        window?.innerHeight -
                        uspRight?.current?.offsetHeight -
                        320,
                    scrollTrigger: {
                        trigger: hero.current,
                        start: "65% 50%",
                        end: "100% 50%",
                        scrub: 1,
                        pin: true,
                        onEnter: () => {
                            gsap.to(".glob-box", {
                                opacity: 0.2,
                                duration: 0.5
                            });
                        },
                        onLeaveBack: () => {
                            gsap.to(".glob-box", {
                                opacity: 0.7,
                                duration: 0.5
                            });
                        }
                    }
                });
            });
        },
        { scope: hero }
    );

    return (
        <section className="relative z-0 pt-[80px] lg:pt-[180px] " ref={hero}>
            {/* <div className="glob-box absolute right-[-300px] top-[15%] z-[1] hidden translate-x-10 overflow-hidden rounded-full opacity-[0] tab:block laptop:right-[-300px]">
                <div className="globe-bottom-grad"></div>
                <div className="globe-right-grad"></div>
                <HeroDotGlobe />
            </div> */}
            <div className="hero-light pointer-events-none">
                <LightSvg />
            </div>
            <div className="container-v2 hidden sm:block">
                <div className="candle-wave-box relative z-[-1] w-[325px] tab:absolute tab:right-[100px] tab:top-[13%] laptop:top-[15%] laptop:right-[200px]">
                    <div className="absolute left-0 top-0 h-full w-[40px] bg-gradient-to-r from-black"></div>
                    <DynamicCandleCanvas />
                    <div className="wave-canvas">
                        <DynamicWaveCanvas />
                    </div>

                    <div className="absolute right-0 top-0 h-full w-[40px] bg-gradient-to-l from-black"></div>
                </div>
            </div>

            <div className="pointer-events-none absolute left-0 top-0 z-[1] h-screen w-screen opacity-25 tab:opacity-70">
                <DynamicStarCanvas />
            </div>
            <div className="mt-10 sm:mt-0">
                <HeroTop />
            </div>
            <div className="cylinder-box  pt-20 tab:h-screen tab:pt-0 ">
                <DynamicCylinderBox>
                    <div
                        className="z-[1] w-full xl:w-1/2 xl:max-w-[600px] xl:translate-y-[10%]"
                        ref={uspRight}
                    >
                        <USPHome />
                    </div>
                </DynamicCylinderBox>
            </div>
        </section>
    );
};

export default Hero;
