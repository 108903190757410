import Link from "next/link";
import posthog from "posthog-js";
import { useRef } from "react";
import { useSelector } from "react-redux";
import DownArrow from "../../icon/DownArrow";
const HeroTop = () => {
    const { startChallengeCTA } = useSelector((state) => state.abTesting);
    const LOG_IN_URL = process.env.LOG_IN_URL || "https://app.fundednext.com";
    const heroTopRef = useRef(null);
    const buttonList = [
        {
            btn_text: "Start Challenge",
            btn_link: "#start-challenge"
        },
        {
            btn_text: "Free Trial",
            btn_link: `${LOG_IN_URL}/subscribe/1?account=swap&account-type=free-trial`
        }
    ];

    const handlePostHog = (btnText) => {
        posthog.capture(`Start Challenge CTA Click`, {
            btnActionType: btnText
        });
    };

    return (
        <div
            className="flex items-center lg:pt-5 xl:pb-[300px] xl:pt-0  tab:h-screen"
            ref={heroTopRef}
        >
            <div className="container-v2">
                <div className="hero-content w-full lg:w-8/12 xl:w-9/12">
                    <span className="flex">
                        <span
                            className="leading-0 rotate-[-1.583deg]  rounded-[5px] px-[8px] py-[1.5px] pt-[2.5px]  text-[10px] font-bold tracking-[2px] text-white xl:text-[12px]"
                            style={{
                                background:
                                    " linear-gradient(90deg, #8055FF 0%, #4D19E0 100%)"
                            }}
                        >
                            OUR PLATFORM
                        </span>
                        <span
                            className="leading-0 translate-x-[-5px]  rotate-[-4.099deg] rounded-[5px] px-[8px] py-[1.5px] pt-[2.5px]  text-[10px] font-bold tracking-[2px] text-black xl:text-[12px]"
                            style={{
                                background:
                                    "  linear-gradient(90deg, #56E8FF 0%, #008AEF 100%)"
                            }}
                        >
                            YOUR SUCCESS
                        </span>
                    </span>
                    <h1
                        aria-label="Fundednext Empowering Promising Traders Worldwide"
                        className="pt-[14px] font-Rebond text-[30px] font-bold leading-[1.2] text-white md:text-[52px] lg:pt-6 xl:text-[64px] 2xl:text-[72px]"
                    >
                        Empowering Promising Traders Worldwide
                    </h1>
                    <p className="pt-5 text-sm  leading-[24px]  text-[rgba(255,255,255,0.75)] md:w-7/12 xl:pt-[30px]  xl:text-xl xl:!leading-[30px]">
                        Trade up to <span className="">$300,000</span> on a
                        FundedNext <br /> Account & earn up to{" "}
                        <span className="">95% of the profits.</span>
                    </p>
                    <div className="flex flex-col  gap-x-5 gap-y-5 pt-[60px]  md:flex-row">
                        {buttonList?.map((item, idx) => {
                            return (
                                <Link key={idx} href={item.btn_link}>
                                    <button
                                        onClick={() =>
                                            handlePostHog(item.btn_text)
                                        }
                                        id={
                                            idx === 0
                                                ? "start-challenge-cta"
                                                : ""
                                        }
                                        className={` ${idx === 0 ? `start-challenge-cta bg-[#635BFF] ${startChallengeCTA === "control" ? "fancy relative  overflow-hidden" : ""}` : "bg-[rgba(255,255,255,0.10)]"} flex w-full items-center justify-center gap-x-[14px] rounded-[76px] py-[20px] text-base font-[500] !tracking-[0.5px] text-white md:w-[302px] lg:font-semibold xl:w-[230px] xl:text-[18px]`}
                                        style={{
                                            letterSpacing: "0.16px;",
                                            leadingTrim: "both",
                                            textEdge: "cap"
                                        }}
                                    >
                                        <span className="z-10 text-white">
                                            {item.btn_text}{" "}
                                        </span>
                                        {idx === 0 && (
                                            <span className="z-10">
                                                <DownArrow />
                                            </span>
                                        )}
                                    </button>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroTop;
