import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const GlobalEvents = () => {
    const [events, setEvents] = useState({});

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.INHOUSE_BASE_URL}/global-events`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const result = await response.json();
            setEvents(result);
        } catch (error) {
            console.error(`Error ==>>`, error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Animation Configuration
    const container = useRef(null);
    useGSAP(
        () => {
            setTimeout(() => {
                gsap.to(".border-anime", {
                    rotate: 100,
                    scrollTrigger: {
                        trigger: container.current,
                        start: "top 50%",
                        end: "top 20%",
                        scrub: 3,
                        ease: "none",
                        onLeave: () => {
                            gsap.to(".border-anime", {
                                rotate: 115,
                                opacity: 0,
                                duration: 1
                            });
                        },
                        onEnterBack: () => {
                            gsap.to(".border-anime", {
                                rotate: 100,
                                opacity: 1,
                                duration: 1
                            });
                        },
                        toggleActions: "restart none none play"
                    }
                });
                const bg =
                    "radial-gradient(61.06% 64.93% at 80% 0%, rgba(179, 85, 224, 0.75) 0%, rgba(94, 24, 182, 0.5) 44.23%, rgba(68, 1, 183, 0.15) 76.41%, rgba(58, 1, 183, 0) 100%)";
                gsap.to(".grad-bg", {
                    background: bg,
                    opacity: 0,
                    scrollTrigger: {
                        trigger: container.current,
                        start: "top 50%",
                        end: "top 40%",
                        scrub: 3,
                        ease: "none",
                        toggleActions: "restart none none play"
                    }
                });
            }, 300);
        },
        { scope: container }
    );
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        // centerPadding: "60px",
        className: "center slider variable-width",
        // centerMode: true,
        waitForAnimate: false,
        lazyLoad: "ondemand",
        pauseOnHover: true,
        focusOnSelect: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1.5
                }
            }
        ]
    };

    return (
        <section
            ref={container}
            className="relative  w-full  overflow-hidden rounded-[20px] lg:rounded-[40px] xl:p-7"
        >
            <div className="border-anime border-gradient-top-left absolute rounded-[40px]"></div>
            <div className="absolute left-[50%] top-[50%] h-[98.5%] w-[99%] translate-x-[-50%] translate-y-[-50%] rounded-[35px] bg-black "></div>

            <div className="relative z-[0] overflow-hidden rounded-[20px] bg-mirage p-[20px] xl:p-20">
                <div
                    className="grad-bg absolute left-0 top-0 h-full w-full"
                    style={{
                        background:
                            "radial-gradient(61.06% 64.93% at 0% 0%, rgba(179, 85, 224, 0.75) 0%, rgba(94, 24, 182, 0.5) 44.23%, rgba(68, 1, 183, 0.15) 76.41%, rgba(58, 1, 183, 0) 100%)"
                    }}
                ></div>
                <div className="absolute right-0 top-0 z-[-1] hidden h-[386px] w-[684px] xl:block">
                    <Image
                        src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/glob-img.png"
                        alt=""
                        width={684}
                        height={386}
                    />
                </div>

                <div className="relative">
                    <p className="flex items-center font-Rebond text-[28px] font-bold text-white xl:text-[60px]">
                        Gl
                        <span className=" w-[20px] xl:w-[35px]">
                            {/* <img
                                src="/spinning-globe.gif"
                                alt="spinning globe"
                                className="w-[16px] md:w-[20px] xl:w-auto"
                            /> */}
                            <Image
                                src="/spinning-globe.gif"
                                alt="spinning globe"
                                layout="responsive"
                                width={30}
                                height={30}
                                className="mt-2 xl:mt-3"
                            />
                        </span>
                        bal Events
                    </p>
                    <p className="relative pb-5  pt-3 font-Inter text-sm font-medium text-white/60 xl:w-7/12 xl:text-lg">
                        Dive into FundedNext's global events, where trading
                        enthusiasts connect, engage, and grow. Each event is a
                        unique opportunity to expand your network and horizons.
                    </p>
                    <Link
                        href="/events"
                        className=" text-base font-medium text-white underline"
                    >
                        Learn more
                    </Link>

                    <div className="slider-container relative pt-[60px]">
                        {/* <div className="absolute right-0 top-[-60px] hidden gap-x-5 xl:top-[-80px] xl:flex">
                            <button
                                onClick={previous}
                                className="flex h-[36px] w-[36px]   cursor-pointer items-center justify-center rounded-[50px] bg-[rgba(255,255,255,0.10)] "
                            >
                                <img
                                    src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/leftArrow.svg"
                                    className="rotate-[180deg]"
                                    alt=""
                                />
                            </button>
                            <button
                                onClick={next}
                                className="flex h-[36px]  w-[36px] cursor-pointer items-center justify-center rounded-[50px] bg-[rgba(255,255,255,0.10)] "
                            >
                                <img
                                    src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/leftArrow.svg"
                                    alt=""
                                />
                            </button>
                        </div> */}

                        <Slider
                            ref={(slider) => {
                                sliderRef = slider;
                            }}
                            {...settings}
                        >
                            {events?.data?.data?.map((item, idx) => {
                                return (
                                    <div key={idx}>
                                        <a href={item.url} target="_blank">
                                            <Image
                                                src={item.image}
                                                alt={item.title}
                                                className="rounded-[14px] px-[6px]"
                                                height={330}
                                                width={259}
                                            />
                                        </a>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GlobalEvents;