import CustomImage from "../../common/CustomImage";

const FeaturedSection = () => {
    const data = [
        {
            title: "Infinity Points",
            description:
                "Earn Infinity Points on FundedNext to get discounts on trading Challenges through active participation.",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/infinity-points.svg"
        },
        {
            title: "Swap-Free Account Available",
            description:
                "Hold your trades overnight without paying or receiving interest with a Swap-Free account.",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/swap.svg"
        },
        {
            title: "Reset & Top-Up",
            description:
                "Reset or top-up your account to restart your trading journey, even if you’ve violated any rules.",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/topup.svg"
        },
        {
            title: "Flexible Add-Ons",
            description:
                "Explore versatile and cutting-edge add-ons to customize and enhance your trading journey.",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/add-ons.svg"
        },
        {
            title: "Free Trial Account",
            description:
                "Fine-tune your trading strategy and try out FundedNext's platforms to experience the best trading conditions with absolutely no risk.",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/free-trial.svg"
        },

        {
            title: "TradingView Integrated Dashboard",
            description:
                "Experience the charting & analysis tools of TradingView from the FundedNext Dashboard.",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/trading-view.svg"
        },
        {
            title: "Affiliate Competitions (coming soon)",
            description:
                "Invite elite traders and win rewards, including the top referral award of $5,000.",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/affiliate.svg"
        }
        // {
        //     title: "Pass Aid",
        //     description:
        //         "Activate the free Pass Aid add-on to automatically progress in the Challenge Phase without needing to actively monitor your trades.",
        //     icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/pass-aid.svg"
        // }
    ];

    const renderFeaturedTitle = () => {
        return (
            <div className="w-full">
                <p className="w-full text-[20px] font-[500] leading-[44px] text-white lg:text-[34px]">
                    Additional Features
                </p>
                {/* <p className=" pt-2 font-Inter text-sm font-normal leading-[22px] text-[rgba(255,255,255,0.75)] md:w-7/12 lg:w-5/12 lg:pt-3 lg:text-[18px] lg:leading-[28px] xl:font-[400]">
                    Each event is a unique opportunity to expand your and
                    horizons in the trading world.
                </p> */}
            </div>
        );
    };

    return (
        <div>
            <div className="container-v2">{renderFeaturedTitle()}</div>

            <div className=" hide-scroll-bar mt-[30px] flex w-full gap-[2px]  overflow-scroll  px-5 md:px-[60px] lg:grid  lg:grid-cols-3  lg:rounded-[20px] xl:mx-auto  xl:mt-10 xl:w-[1212px]  xl:grid-cols-4 xl:overflow-hidden xl:px-0">
                {data?.map((featured, index) => {
                    return (
                        <div
                            className={`flex w-full min-w-[250px] flex-col items-start gap-y-[14px] bg-[rgba(255,255,255,0.03)] p-[30px]  hover:bg-[#ffffff03] ${index === 0 ? "lg:rounded-lt-[20px] rounded-l-[20px] lg:rounded-l-[0px]" : ""}  ${index + 1 === data.length ? "lg:rounded-rb-[20px] rounded-r-[20px] lg:rounded-r-[0px]" : ""}`}
                            key={index}
                        >
                            <CustomImage
                                src={featured.icon}
                                alt=""
                                height={42}
                                width={42}
                                layout={"fixed"}
                            />
                            <p className={`text-lg font-medium text-white `}>
                                {featured.title}
                            </p>
                            <p
                                className={`font-Inter text-[14px] font-normal leading-[24px] text-[rgba(255,255,255,0.60)] `}
                            >
                                {featured.description}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FeaturedSection;